import "./../style/model.css";
import { Form, Input } from "antd";
import { CustomButton } from "../Button";
export function LoginModel({ onFininsh }) {
  return (
    <div className="model-container glow-on-hover">
      <Form onFinish={onFininsh}>
        <Form.Item
          name={"username"}
          rules={[{ required: true, message: "Please enter the username" }]}
          required={true}
        >
          <Input className="model-input" placeholder="Username" />
        </Form.Item>

        <Form.Item
          name={"password"}
          rules={[
            {
              required: true,
              message: "Please enter the password",
            },
          ]}
        >
          <Input
            className="model-input"
            placeholder="Password"
            type="password"
          />
        </Form.Item>
        <CustomButton htmlType="htmlSubmit" name="Login" />
      </Form>
    </div>
  );
}

export default LoginModel;
