import { Router } from "./router";
import { BrowserView, MobileView } from "react-device-detect";

function App() {
  return (
    <>
      <BrowserView>
        <Router />;
      </BrowserView>
      <MobileView>
        <Router />;
      </MobileView>
    </>
  );
}

export default App;
