import { Tabs } from "antd";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Line, Doughnut, PolarArea, Radar } from "react-chartjs-2";
import { colors } from "../..//common/loaclization";
// Register the Graph
ChartJS.register(...registerables);
export function GraphView({ ...props }) {
  const { data, level } = props;
  const getLabels = () => {
    switch (level) {
      case "project":
        return data?.map((item) => item.projectName);
      case "commit":
        return data?.map((item) => item.email);
      case "branch":
        return data?.map((item) => item.branchName);
      default:
        return [];
    }
  };
  const gdata = {
    labels: getLabels(),
    datasets: [
      {
        label: "Commits",
        data: data?.map((item) => item.total),
        backgroundColor: colors,
        borderColor: ["green"],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "StockBot (Buy) Compution",
      },
    },
  };
  return (
    <Tabs defaultActiveKey="1" style={{ padding: "10px" }}>
      <Tabs.TabPane tab="Line" key="1">
        <div className="graph">
          <Line data={gdata} options={options} />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Bar" key="2">
        <div className="graph">
          <Bar data={gdata} options={options} />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="PolarArea" key="3">
        <div className="graph">
          <PolarArea
            data={gdata}
            style={{ height: "500px" }}
            options={options}
          />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Doughnut" key="4">
        <div className="graph">
          <Doughnut
            data={gdata}
            style={{ height: "500px" }}
            options={options}
          />
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Radar" key="5">
        <div className="graph">
          <Radar data={gdata} style={{ height: "500px" }} options={options} />
        </div>
      </Tabs.TabPane>
    </Tabs>
  );
}

export default GraphView;
