import { Button } from "antd";
import "./../style/button.css";
export function CustomButton({ name, disabled, className, ...rest }) {
  return (
    <Button
      className={`custom-button ${className}`}
      {...rest}
      disabled={disabled}
    >
      {name}
    </Button>
  );
}

export default CustomButton;
