import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { verify } from "../API/dispatch";
import { Login, Users } from "../pages";
export function Router() {
  const [auth, setAuth] = useState(true);

  useEffect(() => {
    (async function () {
      const isLogin = await verify();
      if (isLogin) {
        setAuth(true);
        return;
      }
      setAuth(false);
      return;
    })();
  }, []);

  const PrivateRouter = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="ai/:type" element={<Users setAuth={setAuth} />} />
          <Route path="*" element={<Navigate to={"ai/users"} />} />
        </Routes>
      </BrowserRouter>
    );
  };

  const PublicRouters = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route path="*" element={<Navigate to={"/login"} />} />
        </Routes>
      </BrowserRouter>
    );
  };

  return auth ? <PrivateRouter /> : <PublicRouters />;
}
